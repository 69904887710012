import { useEffect, useState } from "react";
import { Button, Popconfirm, Space, Tag, Typography } from "antd";

import ICONS from "../../../config/icons";
import { getColumnSearchProps, parseDate } from "../../../config/config";

import { useStreamerServersOptions } from "../../../hooks/selectOptions";

import TableEmpty from "../../../_components/table/TableEmpty";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import icons from "../../../config/icons";

let statusOption = [
  { text: "Online", value: 1 },
  { text: "Offline", value: 2 },
  { text: "Connecting", value: 0 },
];

export default function StreamerStreamsTable({
  getAgain,
  openDrawer,
  getDataSource,
  openPlayingVideo,
  handleMenuClick,
  // openLogDrawer,
}) {
  const serverOptions = useStreamerServersOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [dataCounter, setDataCounter] = useState(0);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_streams") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("streams")?.page || 1);

  const [sort, setSort] = useState(getAtSession("streams")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("liveTv")?.search || {
      name: null,
      serverId: null,
    }
  );

  const [rowSelected, setRowSelected] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const columns = [
    {
      title: "#",
      // width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Name",
      defaultFilteredValue: getAtSession("streams")?.search?.name,
      filteredValue: getAtSession("streams")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "center",
      fixed: "left",

      ...getColumnSearchProps(),
    },

    {
      title: "Id",
      defaultSortOrder:
        getAtSession("streams")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("streams")?.sort?.[1]),
      dataIndex: "id",
      key: "id",
      align: "center",
      sorter: true,
      // width: 70,
    },

    {
      title: "Uptime",
      dataIndex: "uptime",
      key: "uptime",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record, index) => {
        return parseDate(record.up_time);
      },
    },
    {
      title: "Status",
      defaultFilteredValue: getAtSession("streams")?.search?.status,
      filteredValue: getAtSession("streams")?.search?.status,
      dataIndex: "status",
      key: "status",
      align: "center",
      // width: 150,
      ellipsis: true,
      filters: statusOption,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return (
          <>
            {record.status === 1 ? (
              <Tag color="green" style={{ textAlign: "center" }}>
                Online
              </Tag>
            ) : record.status === 0 ? (
              <Tag color="orange" style={{ textAlign: "center" }}>
                Connecting
              </Tag>
            ) : record.status === 2 ? (
              <Tag color="red" style={{ textAlign: "center" }}>
                Offline
              </Tag>
            ) : (
              <Tag color="red" style={{ textAlign: "center" }}>
                Error
              </Tag>
            )}
            {/* <Button
              icon={<i className="fa fa-terminal" />}
              onClick={() => {
                openLogDrawer(record);
              }}
            /> */}
          </>
        );
      },
    },

    {
      title: "Server",
      defaultFilteredValue: getAtSession("streams")?.search?.serverId,
      filteredValue: getAtSession("streams")?.search?.serverId,
      dataIndex: "serverId",
      key: "serverId",
      align: "center",
      width: 150,
      filters: serverOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return record?.server?.name;
      },
    },

    {
      title: "Error count",
      defaultSortOrder:
        getAtSession("streams")?.sort?.[0] === "errorCount" &&
        asc_desc(getAtSession("streams")?.sort?.[1]),
      dataIndex: "errorCount",
      key: "errorCount",
      align: "center",
      width: 100,
      sorter: true,
    },

    {
      title: "Resolutions",
      dataIndex: "resolutions",
      key: "resolutions",
      align: "center",
      width: 260,
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            {record.resolutions?.map((item, index) => {
              return (
                <Tag style={{ textAlign: "center" }}>
                  {item.name}
                  <span
                    style={{
                      color: "#147973",
                      marginLeft: 5,
                    }}
                  >
                    ({item.info?.pid})
                  </span>
                </Tag>
              );
            })}
          </div>
        );
      },
    },

    {
      title: "Output",
      dataIndex: "output",
      key: "output",
      align: "center",
      width: 240,

      render: (text, record, index) => {
        const cutText = record.output.slice(-18);
        return record.output.length ? (
          <Space>
            <Button
              style={{
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="primary"
              icon={ICONS.PLAY}
              onClick={() => openPlayingVideo(record)}
            />

            <Typography.Paragraph
              style={{ margin: 0, width: 180, display: "flex" }}
              copyable={{
                text: record.output,
              }}
            >
              <div style={{ width: 170 }}>...{cutText}</div>
            </Typography.Paragraph>
          </Space>
        ) : (
          <TableEmpty />
        );
      },
    },

    {
      title: `Updated date`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 140,
      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "Play / Pause",
      dataIndex: "is_paused",
      defaultSortOrder:
        getAtSession("streams")?.sort?.[0] === "is_paused" &&
        asc_desc(getAtSession("streams")?.sort?.[1]),
      align: "center",
      fixed: "right",
      sorter: true,
      render: (text, record, index) => {
        const key = {
          key: record.is_paused ? "play" : "pause",
        };
        return (
          <Space>
            <ButtonComponent
              style={{
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              // type={record.is_paused ? "primary" : "secondary"}
              danger={!record.is_paused}
              icon={!record.is_paused ? ICONS.PAUSE : ICONS.PLAY}
              onClick={(e) => {
                handleMenuClick(key, record);
              }}
            />
          </Space>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: ICONS.EDIT,
            },

            // record.is_paused
            //   ? {
            //       key: "play",
            //       name: "Play",
            //       icon: ICONS.PLAY,
            //       question: `Do you want to play this stream?`,
            //       onConfirm: (e) => {
            //         handleMenuClick(e, record);
            //       },
            //     }
            //   : {
            //       key: "pause",
            //       name: "Pause",
            //       icon: ICONS.PAUSE,
            //       question: `Do you want to pause this stream?`,
            //       onConfirm: (e) => {
            //         handleMenuClick(e, record);
            //       },
            //     },

            {
              key: "delete",
              name: "Delete",
              icon: ICONS.DELETE,
              question: `Do you want to delete this stream?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const streams = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      streams.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_streams", data.limit);
    setInSession("streams", streams);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setRowSelected(newSelectedRowKeys);
  };

  const rowSelection = {
    setRowSelected,
    onChange: onSelectChange,
  };

  const handleBulkAction = (action) => {
    const id = rowSelected.join(",");
    handleMenuClick({ key: action }, { id });
  };

  useEffect(() => {
    if (dataCounter >= 1) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    const fetchLiveTvData = () => {
      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          serverId: search.categories,
        };
      }

      if (search.status && search.status[0] !== undefined) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            status: search.status[0],
          };
        } else {
          query.filter = {
            status: search.status[0],
          };
        }
      }

      const hasSearchKeyWithValue = () => {
        const keys = Object.keys(search);

        return keys.some((key) => search[key] && search[key][0]);
      };

      function onSuccess(data) {
        if (data?.rows.length > 0 || hasSearchKeyWithValue()) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.count);

        setLoading(false);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("streams")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("streams", {
            ...getAtSession("streams"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("streams", {
            ...getAtSession("streams"),
            page: currentPage,
          });
        }
      }

      function onError(err) {
        setLoading(false);
        console.log(err);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (isEmpty && dataCounter > 1) return;
      setDataCounter(dataCounter + 1);

      if (getAtSession("streams") && getAtSession("streams")?.page > currentPage) {
        setInSession("streams", {
          ...getAtSession("streams"),
          page: 1,
        });
        setCurrentPage(getAtSession("streams")?.page);
      }

      fetchLiveTvData();
    }, 500);
    setShowConfirmModal(false);

    return () => {
      setDataCounter(0);
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, getAgain]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <ButtonComponent icon={ICONS.ADD} title="Add Stream" onClick={openDrawer} />
          {rowSelected.length > 0 && (
            <div
              style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 20 }}
            >
              <ButtonComponent
                type="default"
                title={"Play"}
                icon={icons.PLAY}
                onClick={() => {
                  handleBulkAction("play");
                }}
              />
              <ButtonComponent
                type="default"
                title={"Pause"}
                icon={icons.PAUSE}
                onClick={() => {
                  handleBulkAction("pause");
                }}
              />
              <>
                <ButtonComponent
                  type="default"
                  title="Delete"
                  icon={icons.DELETE}
                  onClick={() => setShowConfirmModal(true)}
                />
                <Popconfirm
                  open={showConfirmModal}
                  placement="topLeft"
                  title={"Do you want to delete this streams?"}
                  onConfirm={() => handleBulkAction("delete")}
                  onCancel={() => {
                    setRowSelected([]); // Clear selected rows
                    setShowConfirmModal(false); // Hide confirmation modal
                  }}
                />
              </>
            </div>
          )}
        </div>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      rowSelection={rowSelection}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1800,
      }}
    />
  );
}
